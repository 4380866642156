.fund .well, .funds .well {
  background: #fafafa;
  padding: 16px;
}

.fund .disclaimer {
  font-size: 0.8em;
  text-align: justify;
  font-style: italic;
}

.period-selector {
  position: absolute;
  right: 0;
  z-index: 100;
}

@media only screen and (max-width: 576px) {
  .period-selector {
    bottom: -30px;
  }
}

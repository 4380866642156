.post {
  background: white;
  padding: 20px 20px 1px 20px;
  margin: 15px 0;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.post-menu {
  float: right;
}
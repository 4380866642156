.template .logo {
  width: 150px;
  height: 31px;
  /* background: rgba(255,255,255,.8); */
  background: url(../../../logo.png) no-repeat;
  background-position: center; 
  background-size: 150px;
  margin: 16px 24px 16px 0;
  float: left;
}

.template .ant-layout-header {
  background: #f0f2f5;
}

.template .ant-layout-footer {
  text-align: center;
}

.template .user-menu.ant-dropdown-link {
  float: right;
  height: 50px;
  cursor: pointer;
}

.template .ant-table-row .ant-btn {
  margin: 1px;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.space-5 { margin: 0; margin-top: 5px; }
.space-10 { margin: 0; margin-top: 10px; }
.space-15 { margin: 0; margin-top: 15px; }
.space-20 { margin: 0; margin-top: 20px; }
.space-25 { margin: 0; margin-top: 25px; }
.space-30 { margin: 0; margin-top: 30px; }
.space-40 { margin: 0; margin-top: 40px; }
.space-50 { margin: 0; margin-top: 50px; }
.space-60 { margin: 0; margin-top: 60px; }
.space-70 { margin: 0; margin-top: 70px; }
.space-80 { margin: 0; margin-top: 80px; }
.space-90 { margin: 0; margin-top: 90px; }
.space-100 { margin: 0; margin-top: 100px; }

.validation-message {
  text-align: center;
  font-style: italic;
  font-weight: bold;
}

.editor {
  padding: 0 10px;
}
.editorWrapper {
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
.editorToolbar.rdw-editor-toolbar {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 4px 4px 0 0 ;
}
.rdw-embedded-modal {
  overflow: auto;
}
@media only screen and (max-width: 576px) {
  .ant-layout-header {
    padding: 0px 20px;
  }
  .only-mobile {
    display: inline-block !important;
  }
  .hide-mobile {
    display: none !important;
  }
}
.only-mobile {
  display: none;
}
.hide-mobile {
  display: inline-block;
}
.show-xl {
  display: none;
}
@media only screen and (min-width: 1200px) {
  .show-xl {
    display: inline-block;
  }
}